var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"L8G5rxvgGijXZBG9hpU10"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from './config';

const ignoredMessagesStrings = [
  't.update is not a function',
  't.update',
  "Cannot read properties of undefined (reading 'tagName')",
  'squish',
  'getRootNode',
  "Cannot set properties of undefined (setting 'getAllFrames')",
  'getAllFrames',
  '__squish__clickButton is not defined',
  'tagName',
  'TypeError',
  'TypeError BaseClient.prototype.ot(core/src/baseclient)',
  'typeerror',
  'TypeError: t.update is not a function',
];

const ignoredMessagesRegex = ignoredMessagesStrings.map(
  (message) => new RegExp(`^${message}$`, 'i'),
);

const ignoredMessages = [...ignoredMessagesStrings, ...ignoredMessagesRegex];

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  debug: false,
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,
  attachStacktrace: true,
  ignoreTransactions: [/^.*$/],
  sampleRate: 0,
  enableTracing: false,
  ignoreErrors: ignoredMessages,
  beforeSend(event) {
    if (
      event?.exception?.values?.some((exc) => {
        const message = exc?.value?.toLowerCase();
        if (message) {
          return ignoredMessages.some((ignoredMessage) =>
            ignoredMessage instanceof RegExp
              ? ignoredMessage.test(message)
              : message.includes(ignoredMessage.toLowerCase()),
          );
        }
        return false;
      })
    ) {
      return null;
    }

    return event;
  },
});
