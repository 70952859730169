import dynamic from 'next/dynamic';
import React, { createContext, useContext, useState } from 'react';
import { IUseDeepDiveContext } from 'types/deepDive';

const DeepDiveAudio = dynamic(() => import('@components/deepdive/container'), {
  ssr: false,
});

const DeepDiveContext = createContext<IUseDeepDiveContext>({
  visible: false,
  setVisible: () => {},
  playing: false,
  setPlaying: () => {},
  displayNavOption: false,
  setDisplayNavOption: () => {},
});

export const useDeepDive = () => {
  const { setVisible, visible, displayNavOption, setDisplayNavOption } =
    useContext(DeepDiveContext);

  return {
    isDeepDiveVisible: visible,
    setDeepDiveVisible: setVisible,
    displayNavOption,
    setDisplayNavOption,
  };
};

function DeepDiveProvider({ children }: { children: React.ReactNode }) {
  const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [displayNavOption, setDisplayNavOption] = useState(false);

  return (
    <DeepDiveContext.Provider
      value={{
        visible,
        setVisible,
        playing,
        setPlaying,
        displayNavOption,
        setDisplayNavOption,
      }}
    >
      <>
        <DeepDiveAudio
          visible={visible}
          setVisible={setVisible}
          playing={playing}
          setPlaying={setPlaying}
          displayNavOption={displayNavOption}
          setDisplayNavOption={setDisplayNavOption}
        />
        {children}
      </>
    </DeepDiveContext.Provider>
  );
}

export default DeepDiveProvider;
